import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import { Helmet } from "react-helmet";
import * as styles from "../styles/corousel.module.css";
import Expand from "../assets/expand.png";

const itemsPerPage = 1;
let resetTimeout;

const Corousal = ({ userData }) => {
  let imageRef = React.createRef();
  const altText = [];
  const url = [];

  return (
    <div className={styles.carousalWrapper}>
      <Helmet>
        <link rel="stylesheet" href="/lightbox/lightbox.min.css" />
        <script src="/lightbox/lightbox.min.js" />
      </Helmet>

      <Carousel itemsToShow={itemsPerPage}>
        {userData[0]?.Photo1_URL && (
          <div key={1}>
            <a
              ref={imageRef}
              id="first-image"
              href={userData[0]?.Photo1_URL}
              data-lightbox="profilePic"
            >
              <img
                class="carousel-image"
                src={userData[0]?.Photo1_URL}
                alt={userData[0]?.Alt_Text_Photo1}
              />
            </a>
          </div>
        )}
        {userData[0]?.Photo2_URL && (
          <div key={2}>
            <a href={userData[0]?.Photo2_URL} data-lightbox="profilePic">
              <img
                class="carousel-image"
                src={userData[0]?.Photo2_URL}
                alt={userData[0]?.Alt_Text_Photo2}
              />
            </a>
          </div>
        )}
        {userData[0]?.Photo3_URL && (
          <div key={3}>
            <a href={userData[0]?.Photo3_URL} data-lightbox="profilePic">
              <img
                class="carousel-image"
                src={userData[0]?.Photo3_URL}
                alt={userData[0]?.Alt_Text_Photo3}
              />
            </a>
          </div>
        )}
        {userData[0]?.Photo4_URL && (
          <div key={4}>
            <a href={userData[0]?.Photo4_URL} data-lightbox="profilePic">
              <img
                class="carousel-image"
                src={userData[0]?.Photo4_URL}
                alt={userData[0]?.Alt_Text_Photo4}
              />
            </a>
          </div>
        )}
        {userData[0]?.Photo5_URL && (
          <div key={5}>
            <a href={userData[0]?.Photo5_URL} data-lightbox="profilePic">
              <img
                class="carousel-image"
                src={userData[0]?.Photo5_URL}
                alt={userData[0]?.Alt_Text_Photo5}
              />
            </a>
          </div>
        )}
        {userData[0]?.Video && (
          <div key={7} class="carousel-video-container">
            <div class="carousel-video">
              <iframe
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/" + userData[0]?.Video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </Carousel>
      <div className={styles.expandImg}>
        <img
          src={Expand}
          alt="Icon to view photos in full screen"
          onClick={() => {
            imageRef.current.click();
          }}
        />
      </div>
    </div>
  );
};

export default Corousal;
