/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, useEffect } from "react";
import Profile from "../components/Profile";
import SEO from "../components/seo";
import * as styles from "../styles/profile.module.css";
import * as stylesNavbar from "../styles/navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bird from "../assets/bird.png";
import { Link } from "gatsby";
import HeaderHamburger from "../assets/hamburger.png";
import HamburgerMenu from "react-hamburger-menu";
import Group from "../assets/group.png";
import Close from "../assets/close.png";

// Subscribe
import * as stylesHam from "../styles/hamburger.module.css";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { graphql } from "gatsby";
import { subscribeForm } from "../components/helpers";
import { Helmet } from "react-helmet";

import { useCookies } from "react-cookie";

export const query = graphql`
  query Query2 {
    allAirtable(filter: { table: { eq: "Data" } }) {
      edges {
        node {
          table
          data {
            Name
            Alt_Photos
            Alt_Text_Photo1
            Alt_Text_Photo2
            Alt_Text_Photo3
            Alt_Text_Photo4
            Alt_Text_Photo5
            Alt_Text_Video
            Photo1_URL
            Photo2_URL
            Photo3_URL
            Photo4_URL
            Photo5_URL
            Photo6_URL
            Name_English
            Language
            CDN_Photo_URL {
              url
            }
            Changed_By {
              email
              id
              name
            }
            Changed_On
            Created_By {
              email
              id
              name
            }
            Created_On
            Disability
            Gender
            ID
            Instagram_Content
            Language
            Photos {
              filename
              url
            }
            Quote
            Status
            Video
            Website_Content
            State_name
            Display_Order
          }
          recordId
        }
      }
    }
  }
`;

const profile = ({ pageContext, location, data }) => {
  const allUserData = data.allAirtable.edges.map((node) => node.node.data);
  const personNameUrl = pageContext.pathURL;
  const [showSubscribe, setShowSubscribe] = useState(false);
  const language = pageContext.language;

  const [cookies, setCookie] = useCookies([]);
  const updateCookie = (lang) => {
          setCookie("language", lang, { path: "/" });
  } ;


  let allStoriesLink = "/";
  if (!pageContext.isDefaultLanguage) {
    allStoriesLink = `/${language}`;
  }
  console.log(pageContext);
  const handleToggleSubscribe = (value) => {
    setShowSubscribe(value);
  };
  const [open, setOpen] = useState(false);
  const profileSubHandle = () => {
    setShowSubscribe(false);
    setOpen(!open);
  };

  let allUsers = [];
  useEffect(() => {
    allUsers = localStorage.getItem("data");
    allUsers = JSON.parse(allUsers);
  }, [showSubscribe]);

  const profileUser = allUserData.filter((person) => {
    if (person.Status === "Published" && person.Language === language) {
      let personName = person.Name_English.toLowerCase().trim();
      personName = personName.replace(/\s/g, "-");

      return personNameUrl === personName;
    }
  });

  let name = profileUser[0]?.Name.toLowerCase().trim();
  let state = profileUser[0]?.State_name.toLowerCase().trim().split(" ");
  let nameDesktop = name?.split(" ");
  let nameMobile = name?.split(" ")[0];
  const getProfile = () => (
    <div className={styles.inclusiveProfileWrapper}>
      <SEO
        title={`Everyone is Good at Something - Photo story of ${profileUser[0]?.Name}`}
        description={profileUser[0]?.Quote}
        image={profileUser[0]?.Photo1_URL}
        image_resize={profileUser[0]?.Photo6_URL}
      />
      <section className={stylesNavbar.navbar}>
        <div className={`${stylesNavbar.navCont} ${stylesNavbar.navFlex}`}>
          <div className={styles.inclusiveNavbarHeaderWrapper}>
            <Link to="/">
              <div className={styles.inclusiveBirdImgWrapper}>
                <img
                  src={bird}
                  alt="Logo of Everyone is good at something"
                  className={stylesNavbar.logoContainer}
                />
              </div>
            </Link>

            <h2 className={stylesNavbar.mediah2}>{nameMobile}</h2>
            <h2 className={stylesNavbar.headerh2}>
              {nameDesktop?.map((word, i) => (
                <span key={i}>
                  {" "}
                  {word}{" "}
                  {i !== nameDesktop.length - 1 && (
                    <div className={stylesNavbar.headerDot}></div>
                  )}
                </span>
              ))}
              <span>,</span>
              {state?.map((word, i) => (
                <span key={i}>
                  {" "}
                  {word}{" "}
                  {i !== state.length - 1 && (
                    <div className={stylesNavbar.headerDot}></div>
                  )}
                </span>
              ))}
            </h2>
          </div>
          <ul>
            <li className={stylesNavbar.allStoriesContainer}>
              <h1>
                <span className={stylesNavbar.allStoriesHover}>
                  <Link to={allStoriesLink}>{pageContext.messages.all}</Link>
                </span>
              </h1>
            </li>

            <div className={stylesNavbar.rubiks}>
              {showSubscribe && (
                <li>
                  <img
                    alt="Menu icon"
                    className={stylesNavbar.hamburger}
                    onClick={() => {
                      handleToggleSubscribe(!showSubscribe);
                      setOpen(!open);
                    }}
                    src={Group}
                  />
                </li>
              )}
              {!showSubscribe && (
                <li>
                  <img
                    alt="Menu icon"
                    className={stylesNavbar.hamburger}
                    onClick={() => {
                      handleToggleSubscribe(!showSubscribe);
                      setOpen(!open);
                    }}
                    src={HeaderHamburger}
                  />
                </li>
              )}
            </div>
            <div className={stylesNavbar.hamMenu}>
              <li>
                <HamburgerMenu
                  isOpen={open}
                  menuClicked={() => {
                    profileSubHandle();
                    handleToggleSubscribe(!showSubscribe);
                  }}
                  width={30}
                  height={25}
                  strokeWidth={1}
                  rotate={0}
                  color="black"
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </li>
            </div>
          </ul>
        </div>
      </section>
      {showSubscribe && (
        <section className={stylesHam.subscribeWrapper}>
          <div className={`${stylesHam.profileCard} ${stylesHam.flex}`}>
            <span className={`${stylesHam.closeIcon} ${stylesHam.socialStyle}`}>
              <img
                className={stylesNavbar.closeSubscribe}
                src={Close}
                alt="Close button"
                onClick={() => {
                  handleToggleSubscribe(!showSubscribe);
                  setOpen(!open);
                }}
              />
            </span>
            <div className={stylesHam.form}>
              <span
                className={`${stylesHam.iconClass} ${stylesHam.socialStyle}`}
              >
                <a
                  title="Share on Instagram"
                  href="https://www.instagram.com/_everyoneisgoodatsomething_/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} size={"2x"} />
                </a>
                <a
                  title="Share on Facebook"
                  href="https://www.facebook.com/IndiaInclusionSummit"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} size={"2x"} />
                </a>
                <a
                  title="Tweet about this on Twitter"
                  href="https://twitter.com/IndiaInclusion"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTwitter} size={"2x"} />
                </a>
              </span>

              <div className={stylesHam.notifyWrapperText}>
                <span>{pageContext.messages.notifyOnNewStory}</span>
              </div>
              <div
                dangerouslySetInnerHTML={subscribeForm(
                  false,
                  language,
                  pageContext.messages
                )}
              />

              <div className={stylesHam.contactStoryWrapper}>
                <span className={stylesHam.contactStorySpan}>
                  {pageContext.messages.shareAStory} <br />
                  <span className={styles.contactMailWrapper}>
                    <a href="mailto:stories.egs@gmail.com">
                      stories.egs@gmail.com
                    </a>
                  </span>
                </span>
              </div>
              <br/>
            <div className={stylesHam.notifyWrapperText} id="testingId1">
              <Link onClick={() => updateCookie("en")} to="/">
                {pageContext.messages.english}
              </Link> 
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link onClick={() => updateCookie("hi")} to="/hi">
                {pageContext.messages.hindi}
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link onClick={() => updateCookie("bn")} to="/bn">
                {pageContext.messages.bengali}
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link onClick={() => updateCookie("ta")} to="/ta">
                {pageContext.messages.tamil}
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link onClick={() => updateCookie("ml")} to="/ml">
                {pageContext.messages.malayalam}
              </Link>
               &nbsp;&nbsp;&nbsp;&nbsp;
              <Link onClick={() => updateCookie("kn")} to="/kn">
                {pageContext.messages.kannada}
              </Link>
          </div>
          <br/>
          <br/>


            </div>
          </div>
        </section>
      )}
      <div className={styles.dataContainer}>
        {!showSubscribe && (
          <Profile
            profileUser={profileUser}
            location={location}
            messages={pageContext.messages}
            onClick={() => console.log("profile Clicked")}
          />
        )}
      </div>
    </div>
  );

  useEffect(() => {
    getProfile();
  }, [showSubscribe]);

  return getProfile();
};

export default profile;
