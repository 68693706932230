import React from "react";
import * as styles from "../styles/profile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import Corousal from "../components/Corousal";

const Profile = ({ profileUser, messages }) => {
  return (
    <section className={styles.profileWrapper}>
      <div className={styles.profileCard}>
        <Corousal userData={profileUser} />

        <h3 className={styles.userQuote}>{profileUser[0]?.Quote}</h3>
        <p className={styles.userStoryText}>
          {profileUser[0]?.Website_Content.split("\n").map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </p>

        <div className={styles.flexWrapper}>
          {messages.photos}:{" "}
          <h4 className={styles.creditsHeader}>
            {messages.vickyRoy}{" "}
            <a href="https://www.vickyroy.in/" target="_blank">
              <FontAwesomeIcon
                className={styles.externalLinkIcon}
                icon={faExternalLinkAlt}
                size={"1x"}
              />
            </a>
          </h4>
        </div>
        <div className={styles.copyrightWrapper}>
          ©Copyrights -&nbsp;
          <a
            href="https://creativecommons.org/licenses/by-sa/4.0/"
            target="_blank"
          >
            https://creativecommons.org/licenses/by-sa/4.0/
          </a>
        </div>
      </div>
    </section>
  );
};

export default Profile;
